<template>
  <div>
    <base-header class="pb-1">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Motivos</h6>
        </div>
      </div>
    </base-header>
  
    <div class="container-fluid mt--6">
      <card>
        <h3 slot="header" class="mb-0">Motivo</h3>
        <div class="form-row mt-3">
          <div class="col-md-8">
            <base-input label="Descrição" name="descricao" placeholder="Descrição" required v-model="reason.description">
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input label="Ativo" required name="ativo">
              <el-select v-model="reason.isActive" placeholder="Ativo?">
                <el-option v-for="option in opcoes" :key="option.label" :label="option.label" :value="option.value">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input label="Disponível no Site" required name="avaliableOnline">
              <el-select v-model="reason.avaliableOnline" placeholder="Disponível no Site?">
                <el-option v-for="option in opcoes" :key="option.label" :label="option.label" :value="option.value">
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>
      </card>
  
      <div class="col-md-12 text-center mb-3">
        <base-button type="primary" @click="save">Salvar</base-button>
        <base-button type="secondary" @click="cancel">Cancelar</base-button>
      </div>
  
    </div> <!--- fecha conteudo -->
  
  </div>
</template>
<script>
import { Select, Option } from 'element-ui'

export default {
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mounted() {
    this.loadReason();
  },
  data() {
    return {
      opcoes: [
        { label: 'Sim', value: true },
        { label: 'Não', value: false }
      ],
      reason: {
        id: null,
        description: null,
        isActive: true,
        avaliableOnline: true
      }
    };
  },
  methods: {
    loadReason() {
      if (this.id != 0) {
        this.$clubApi.get('/support/reason/' + this.id)
          .then((response) => {
            this.reason = response.data.object
          }).catch((error) => {
            this.$notify({ type: 'warning', message: error.response.data.msg })
          }).finally(() => {
            NProgress.done()
          })
      }
    },
    save() {
      this.$clubApi.post('/support/reason/save', this.reason)
        .then(() => {
          location.href = '/configuracoes/motivos';
          //this.$notify({type: 'success', message: "Motivo do cancelamento salvo com sucesso"})
        }).catch((error) => {
          this.$notify({ type: 'warning', message: error.response.data.msg })
        }).finally(() => {
          NProgress.done()
        })
    },
    cancel() {
      location.href = '/configuracoes/motivos';
    }
  }
};
</script>
<style></style>
